import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

import { OrderSettings } from '../models/order-settings';

/**
 * Class providing access methods for order settings.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderSettingsAccess {

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore
  ) {
  }

  /**
   * Returns all order settings for a location.
   *
   * @param locationId the ID of the location
   * @returns the found order settings, otherwise empty list
   */
  public getOrderSettings(locationId: string): Observable<OrderSettings[]> {
    return this.afs.collection<OrderSettings>('orderSettings', ref => ref.where('locationId', '==', locationId))
      .valueChanges().pipe(
        map((orderSettingsJson) => {
          return orderSettingsJson as OrderSettings[];
        })
      );
  }
}
