import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { Location } from '../models/location';
import { LocationImage } from '../models/location-image';
import { LocationPlace } from '../models/location-place';
import { OrderService } from '../models/order-service';
import { LocationAccess } from '../access/location-access.service';
import { OrderServiceAccess } from '../access/order-service-access.service';
import { GeoPositionAccess } from '../access/geo-position-access.service';
import { FoursquareLocationAccess } from '../access/foursquare-location-access.service';
import { ReservationSettingsManager } from './reservation-settings-manager.service';
import { OrderSettingsManager } from './order-settings-manager.service';
import { ReservationSettingsAdapter } from './reservation-settings.adapter';
import { OrderSettingsAdapter } from './order-settings.adapter';

/**
 * Class providing management methods for locations.
 */
@Injectable({
  providedIn: 'root'
})
export class LocationManager {

  /**
   * The default constructor.
   */
  constructor(
    private geoPositionAccess: GeoPositionAccess,
    private locationAccess: LocationAccess,
    private orderServiceAccess: OrderServiceAccess,
    private foursquareLocationAccess: FoursquareLocationAccess,
    private reservationSettingsManager: ReservationSettingsManager,
    private orderSettingsManager: OrderSettingsManager
  ) {
  }

  /**
   * Add new Location to cloud.
   *
   * @param location the Location object to add
   * @return the id of the new Location
   */
  public async addLocation(location: Location): Promise<string> {
    if (location.id) {
      await this.locationAccess.updateLocation(location.id, location);
      return location.id;
    } else {
      return await this.locationAccess.addLocation(location);
    }
  }

  /**
   * Returns all locations.
   *
   * @returns the found locations, otherwise empty list
   */
  public getAllLocations(): Observable<Location[]> {
    return this.locationAccess.getAllLocations().pipe(
      switchMap(async (locations: Location[]) => {
        return await this.applySettingsToLocations(locations);
      })
    );
  }

  /**
   * Returns all locations of an account.
   *
   * @param accountId the ID of the account
   * @returns the found locations, otherwise empty list
   */
  public getAllLocationsOfAccount(accountId: string): Observable<Location[]> {
    return this.locationAccess.getAllLocationsOfAccount(accountId).pipe(
      switchMap(async (locations: Location[]) => {
        return await this.applySettingsToLocations(locations);
      })
    );
  }

  /**
   * Returns the Locations specified by the IDs.
   *
   * @param locationIds the Location IDs
   * @returns the found locations, otherwise empty list
   */
  public getLocations(locationIds: string[]): Observable<Location[]> {
    return this.locationAccess.getLocations(locationIds).pipe(
      switchMap(async (locations: Location[]) => {
        return await this.applySettingsToLocations(locations);
      })
    );
  }

  /**
   * Returns the Location specified by the ID.
   *
   * @param locationId the Location ID
   * @returns the found location, otherwise undefined
   */
  public getLocation(locationId: string): Observable<Location | undefined> {
    return this.locationAccess.getLocation(locationId).pipe(
      switchMap(async (location: Location | undefined) => {
        if (location) {
          return await this.applySettingsToLocation(location);
        }
        return location;
      })
    );
  }

  /**
   * Returns the Location specified by the link name.
   *
   * @param locationLinkName the Location link name
   * @returns the found location, otherwise undefined
   */
  public getLocationByLinkName(locationLinkName: string): Observable<Location | undefined> {
    return this.locationAccess.getLocationByLinkName(locationLinkName).pipe(
      switchMap(async (location: Location | undefined) => {
        if (location) {
          return await this.applySettingsToLocation(location);
        }
        return location;
      })
    );
  }

  /**
   * Removes Locations.
   *
   * @param locationIds the IDs of the Locations to remove
   */
  public async removeLocations(locationIds: string[]): Promise<void> {
    for (const locationId of locationIds) {
      const services: OrderService[] = await this.orderServiceAccess.getAllOrderServices(locationId).pipe(first()).toPromise();
      if (services.length > 0) {
        await this.orderServiceAccess.removeOrderServices(services.map((service: OrderService) => service.id));
      }
    }

    return this.locationAccess.removeLocations(locationIds);
  }

  /**
   * Changes visibility of Locations.
   *
   * @param locationIds the IDs of the Locations to change
   * @param visible possible values are 'visible', 'hidden'
   */
  public async setVisibilityOfLocations(locationIds: string[], visible: string): Promise<void> {
    return this.locationAccess.setVisibilityOfLocations(locationIds, visible);
  }

  /**
   * Add new LocationImage to cloud.
   *
   * @param locationImage the LocationImage object to add
   * @return the id of the new LocationImage
   */
  public async addLocationImage(locationImage: LocationImage): Promise<string> {
    return this.locationAccess.addLocationImage(locationImage);
  }

  /**
   * Returns the LocationImage specified by the ID.
   *
   * @param locationImageId the LocationImage ID
   * @returns the found locationImage, otherwise undefined
   */
  public getLocationImage(locationImageId: string): Observable<LocationImage | undefined> {
    return this.locationAccess.getLocationImage(locationImageId);
  }

  /**
   * Removes LocationImages.
   *
   * @param locationImageIds the IDs of the LocationImages to remove
   */
  public async removeLocationImages(locationImageIds: string[]): Promise<void> {
    return this.locationAccess.removeLocationImages(locationImageIds);
  }

  /**
   * Returns locations nearby whose titles match the search text.
   *
   * @param searchText the search text, optional
   * @param radius the search radius, default 300m, null for unlimited
   * @param limit the maximum number of results, default 30
   * @returns the matching locations, otherwise empty list
   */
  public async findLocationsNearby(searchText?: string, radius: number | null = 300, limit: number = 30): Promise<Location[]> {
    const position: number[] = await this.geoPositionAccess.getCurrentPosition();
    const locations = await this.foursquareLocationAccess.findLocationsNearby(position[0], position[1], searchText, radius, limit);
    return await this.applySettingsToLocations(locations);
  }

  /**
   * Returns nearby locations.
   *
   * @param latitude the position's latitude of the user
   * @param longitude the position's longitude of the user
   * @param searchText the search text, optional
   * @param radius the search radius, default 300m, null for unlimited
   * @param limit the maximum number of results, default 30
   * @returns the matching locations, otherwise empty list
   */
  public async findLocations(latitude: number, longitude: number, searchText?: string,
                             radius: number | null = 300, limit: number = 30): Promise<Location[]> {
    const locations = await this.foursquareLocationAccess.findLocationsNearby(latitude, longitude, searchText, radius, limit);
    return await this.applySettingsToLocations(locations);
  }

  /**
   * Add new LocationPlace to cloud.
   *
   * @param locationPlace the LocationPlace object to add
   * @return the id of the new LocationPlace
   */
  public async addLocationPlace(locationPlace: LocationPlace): Promise<string> {
    return this.locationAccess.addLocationPlace(locationPlace);
  }

  /**
   * Returns all LocationPlaces of an account.
   *
   * @param accountId the ID of the account
   * @returns the found LocationPlaces, otherwise empty list
   */
  public getAllLocationPlacesOfAccount(accountId: string): Observable<LocationPlace[]> {
    return this.locationAccess.getAllLocationPlacesOfAccount(accountId);
  }

  /**
   * Returns all LocationPlaces of a location.
   *
   * @param locationId the ID of the location
   * @returns the found LocationPlaces, otherwise empty list
   */
  public getAllLocationPlaces(locationId: string): Observable<LocationPlace[]> {
    return this.locationAccess.getAllLocationPlaces(locationId);
  }

  /**
   * Returns the LocationPlace specified by the ID.
   *
   * @param locationPlaceId the LocationPlace ID
   * @returns the found LocationPlace, otherwise undefined
   */
  public getLocationPlace(locationPlaceId: string): Observable<LocationPlace | undefined> {
    return this.locationAccess.getLocationPlace(locationPlaceId);
  }

  /**
   * Removes LocationPlaces.
   *
   * @param locationPlaceIds the IDs of the LocationPlaces to remove
   */
  public async removeLocationPlaces(locationPlaceIds: string[]): Promise<void> {
    return this.locationAccess.removeLocationPlaces(locationPlaceIds);
  }

  /**
   * Applies reservation and order settings to an array of locations
   *
   * @param locations The locations to update
   * @returns The updated locations
   */
  private async applySettingsToLocations(locations: Location[]): Promise<Location[]> {
    const updatedLocations: Location[] = [];

    for (const location of locations) {
      const updatedLocation = await this.applySettingsToLocation(location);
      updatedLocations.push(updatedLocation);
    }

    return updatedLocations;
  }

  /**
   * Applies reservation and order settings to a single location
   *
   * @param location The location to update
   * @returns The updated location
   */
  private async applySettingsToLocation(location: Location): Promise<Location> {
    try {
      const reservationSettings = await this.reservationSettingsManager.getReservationSettings(location.id)
        .pipe(first()).toPromise();

      ReservationSettingsAdapter.mapReservationSettingsToLocation(location, reservationSettings);

      const orderSettings = await this.orderSettingsManager.getOrderSettings(location.id)
        .pipe(first()).toPromise();

      OrderSettingsAdapter.mapOrderSettingsToLocation(location, orderSettings);
    } catch (error) {
      console.error('Error applying settings to location:', error);
    }

    return location;
  }
}
