import { BlockedPeriod, BusinessHours, DeliveryRule } from './location';


/**
 * The input field for data needed for this type of order.
 */
export enum OrderServiceInputField {
  /** Input field for customer's first name. */
  FIRSTNAME = 'FIRSTNAME',
  /** Input field for customer's last name. */
  LASTNAME = 'LASTNAME',
  /** Input field for customer's email address. */
  EMAIL = 'EMAIL',
  /** Input field for customer's phone number. */
  PHONE_NUMBER = 'PHONE_NUMBER',
  /** Input field for customer's company name. */
  COMPANY_NAME = 'COMPANY_NAME',
  /** Input field for the LocationPlace ID. */
  LOCATION_PLACE_ID = 'LOCATION_PLACE_ID',
  /** Input field for customer's address. */
  ADDRESS = 'ADDRESS',
  /** Input field for customer's floor. */
  FLOOR = 'FLOOR'
}

/**
 * The Order service type.
 */
export enum OrderServiceType {
  /** Newly created order. */
  PICKUP = 'PICKUP',
  /** Order placed by the customer. */
  DELIVERY = 'DELIVERY',
  /** Order accepted by the user. */
  DINE_IN = 'DINE_IN',
}

export enum OrderServiceProvider {
  GOOGLE_RESERVE = 'GOOGLE_RESERVE',
  ORDERDIRECT = 'ORDERDIRECT',
}

/**
 * Represents an order service like takeaway or delivery.
 */
export interface OrderSettings {
  /** The unique ID of this object. */
  id: string;

  /** The unique (consecutive) ID of this object. */
  consecutiveId?: number | null;
  /** The account's unique ID the object belongs to. */
  accountId: string;
  /** The location's unique ID the object belongs to. */
  locationId: string;

  /**
   * The service type.
   */
  serviceType: OrderServiceType;

  /** Service providers connected to the order like OrderDirect (ORDERDIRECT) */
  serviceProviders?: OrderServiceProvider[] | null;

  /** Indicates whether the service is activated. */
  isActivated: boolean;

  /**
   * The general opening hours during which the business location is open.
   * This reflects when the location is accessible or operational,
   * regardless of whether specific services are offered at that time.
   * Default: none; only exists if explicitly set.
   */
  operationHours?: BusinessHours | null;

  /**
   * The specific hours during which this service is actively provided.
   * This may be a subset of the operationHours and completely different from the location's general businessHours,
   * for example when delivery service is available only during certain times.
   * Default: none; only exists if explicitly set. If not set, location's businessHours apply.
   */
  serviceHours?: BusinessHours | null;

  /** The blocked periods of time during which a business location is closed temporarily. */
  blockedPeriods?: BlockedPeriod[] | null;

  /** Comma-separated payment method types, e.g. 'ON_SITE', 'ON_SITE:EC', 'CARD'. */
  paymentMethodTypes?: string[] | null;
  /** Indicates whether sending order receipts is enabled. */
  enableReceipts: boolean;
  /** Indicates whether tipping is enabled. */
  enableTips: boolean;
  /** Required input fields for this service. */
  inputFields?: OrderServiceInputField[] | null;

  // Notification settings, override the location settings
  /** Indicates whether sending text messages to location is enabled. */
  enableTextMessages?: boolean | null;

  /** The IDs of supported diets. */
  supportedDiets?: string[] | null;

  /** Text related to this service. */
  note?: string | null;
  /** Indicates whether asap-ordering is enabled. */
  enableAsap: boolean;
  /** Indicates whether pre-ordering is enabled. */
  enableAdvance: boolean;

  /** The minimum number of minutes to book in advance. */
  minMinutesToBook: number;
  /** The maximum number of minutes to book in advance. */
  maxMinutesToBook: number;

  /** DELIVERY: The rules for delivering. */
  deliveryRules?: DeliveryRule[] | null;

  /**
   * If set to true, the OrderService will include every menu.
   *
   * Only one of eligibleMenuIds or eligibleAllMenus can be set.
   */
  eligibleAllMenus: boolean;
  /**
   * Unique IDs for any CatalogMenu included in this OrderService.
   *
   * Only one of eligibleMenuIds or eligibleAllMenus can be set.
   */
  eligibleMenuIds?: string[] | null;

  /** The time when the object was created, in RFC 3339 format. */
  createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  updatedAt: string;
}
