
/**
 * The Reservation service type.
 */
export enum ReservationServiceType {
  /** On-site table booking. */
  TABLE = 'TABLE',
  /** External reservation service. */
  EXTERNAL = 'EXTERNAL',
}

export enum ReservationServiceProvider {
  GOOGLE_RESERVE = 'GOOGLE_RESERVE',
}

export interface ReservationSettings {
  /** The unique ID of this object. */
  id: string;

  /** The unique (consecutive) ID of this object. */
  consecutiveId?: number;
  /** The account's unique ID the object belongs to. */
  accountId: string;
  /** The location's unique ID the object belongs to. */
  locationId: string;

  /** The service type. */
  serviceType: ReservationServiceType;

  /** Service providers connected to the reservation like Reserve with Google (GOOGLE_RESERVE) */
  serviceProviders?: ReservationServiceProvider[] | null;

  /** Indicates whether the service is activated. */
  isActivated: boolean;

  /** The external URL for the reservation service. */
  externalUrl?: string | null;

  // Notification settings
  /** The email addresses to send notifications to. */
  emails?: string[] | null;
  /** The phone number to send notifications. */
  phoneNumber?: string | null;
  /** Indicates whether text message is enabled. */
  enableTextMessages: boolean;
  /** Indicates whether phone number should be displayed to end consumer. */
  showPhoneNumber: boolean;

  /** The minimum number of minutes to book in advance. */
  minMinutesToBook: number;
  /** The maximum number of minutes to book in advance. */
  maxMinutesToBook: number;
  /** The minimum duration of a reservation in minutes. */
  minDuration: number;
  /** The maximum duration of a reservation in minutes. */
  maxDuration?: number | null;
  /** The minimum number of people for a reservation. */
  minSize: number;
  /** The maximum number of people for a reservation. */
  maxSize: number;

  /** The note to display on the reservation page. */
  note?: string | null;

  /** The time interval in minutes for reservation slots. */
  slotInterval: number;
  /** The time buffer in minutes for reservation slots. */
  slotBuffer: number;

  /** The time when the object was created, in RFC 3339 format. */
  createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  updatedAt: string;
}
