import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

import { ReservationSettings } from '../models/reservation-settings';

/**
 * Class providing access methods for reservation settings.
 */
@Injectable({
  providedIn: 'root'
})
export class ReservationSettingsAccess {

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore
  ) {
  }

  /**
   * Returns all reservation settings for a location.
   *
   * @param locationId the ID of the location
   * @returns the found reservation settings, otherwise empty list
   */
  public getReservationSettings(locationId: string): Observable<ReservationSettings[]> {
    return this.afs.collection<ReservationSettings>('reservationSettings', ref => ref.where('locationId', '==', locationId))
      .valueChanges().pipe(
        map((reservationSettingsJson) => {
          return reservationSettingsJson as ReservationSettings[];
        })
      );
  }
}
