import { BlockedPeriod, BusinessHours, DeliveryRule } from './location';


/**
 * The input field for data needed for this type of order.
 */
export enum OrderServiceInputFieldConstants {
  /** Input field for customer's first name. */
  FIRSTNAME = 'FIRSTNAME',
  /** Input field for customer's last name. */
  LASTNAME = 'LASTNAME',
  /** Input field for customer's email address. */
  EMAIL = 'EMAIL',
  /** Input field for customer's phone number. */
  PHONE_NUMBER = 'PHONE_NUMBER',
  /** Input field for customer's company name. */
  COMPANY_NAME = 'COMPANY_NAME',
  /** Input field for the LocationPlace ID. */
  LOCATION_PLACE_ID = 'LOCATION_PLACE_ID',
  /** Input field for customer's address. */
  ADDRESS = 'ADDRESS',
  /** Input field for customer's floor. */
  FLOOR = 'FLOOR'
}

/**
 * The Order service type.
 */
export enum OrderServiceTypeConstants {
  /** Newly created order. */
  PICKUP = 'PICKUP',
  /** Order placed by the customer. */
  DELIVERY = 'DELIVERY',
  /** Order accepted by the user. */
  DINE_IN = 'DINE_IN'
}

/**
 * Represents an order service like takeaway or delivery.
 */
export class OrderService {

  /** The unique ID of this object. */
  public id: string;
  /** The unique (consecutive) ID of this object. */
  public consecutiveId?: number;
  /** The account's unique ID the object belongs to. */
  public accountId: string;
  /** The location's unique ID the object belongs to. */
  public locationId: string;

  /**
   * The service's type.
   */
  public type: OrderServiceTypeConstants;

  /** The service's visibility, e.g. 'hidden', 'visible'. */
  public visibility = 'visible';

  /**
   * The hours of operation for a business location.
   * Default: none; only exists if explicitly set.
   */
  public operationHours?: BusinessHours;

  /**
   * The hours of service for a business location.
   * Default: none; only exists if explicitly set.
   */
  public serviceHours?: BusinessHours;

  /** The blocked periods of time during which a business location is closed temporarily. */
  public blockedPeriods?: BlockedPeriod[];

  /** The partners connected to the service like OrderDirect (ORDERDIRECT), comma-separated. */
  public orderingServices?: string;
  /** Comma-separated payment method types, e.g. 'ON_SITE', 'ON_SITE:EC', 'CARD'. */
  public paymentMethodTypes?: string;
  /** Indicates whether sending order receipts is enabled. */
  public enableReceipts = false;
  /** Indicates whether tipping is enabled. */
  public enableTips = false;
  /** Indicates whether sending text messages to location is enabled. */
  public enableTextMessages = false;
  /** Comma-separated input fields. */
  public inputFields?: string;

  /** Text related to this service. */
  public note?: string;
  /** Indicates whether asap-ordering is enabled. */
  public enableAsap = false;
  /** Indicates whether pre-ordering is enabled. */
  public enableAdvance = false;

  /** Minimum time in advance for an order (in minutes). */
  public advanceBookingRequirementMin: number;
  /** Maximum time in advance for an order (in minutes). */
  public advanceBookingRequirementMax: number;

  /** DELIVERY: The rules for delivering. */
  public deliveryRules?: DeliveryRule[];

  /**
   * Unique IDs for any CatalogMenu included in this OrderService.
   *
   * Only one of eligibleMenuIds or eligibleAllMenus can be set.
   */
  public eligibleMenuIds?: string[];
  /**
   * If set to true, the OrderService will include every menu.
   *
   * Only one of eligibleMenuIds or eligibleAllMenus can be set.
   */
  public eligibleAllMenus = true;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;
}
