import { Location } from '../models/location';
import { ReservationServiceType, ReservationSettings } from '../models/reservation-settings';

/**
 * Utility class for ReservationSettings.
 */
export class ReservationSettingsAdapter {
  public static mapReservationSettingsToLocation(location: Location, reservationSettingsObjects: ReservationSettings[]): Location {
    location.useReservations = false;
    location.reservationType = 'internal';
    location.reservationServices = '';
    location.reservationExternalUrl = '';
    location.reservationEmails = '';
    location.reservationPhoneNumber = '';
    location.reservationTextMessage = false;
    location.reservationShowPhoneNumber = false;
    location.reservationStartOffset = 0;
    location.reservationMaxTimeInAdvance = 28;
    location.reservationMaxSize = 2;
    location.reservationNote = '';

    const externalReservationSettings = reservationSettingsObjects.find((reservationSetting) => reservationSetting.serviceType === ReservationServiceType.EXTERNAL);
    if (externalReservationSettings && externalReservationSettings.isActivated) {
      location.useReservations = externalReservationSettings.isActivated || false;
      location.reservationType = 'external';
      location.reservationServices = externalReservationSettings.serviceProviders?.join(',') || '';
      location.reservationExternalUrl = externalReservationSettings.externalUrl || '';
      location.reservationEmails = (externalReservationSettings.emails || location['notificationEmails'])?.join(',') || '';
      location.reservationPhoneNumber = externalReservationSettings.phoneNumber || location['notificationPhoneNumber'] || '';
      location.reservationTextMessage = typeof externalReservationSettings.enableTextMessages === 'boolean' ? externalReservationSettings.enableTextMessages : location['notificationEnableTextMessages'] || false;
      location.reservationShowPhoneNumber = typeof externalReservationSettings.showPhoneNumber === 'boolean' ? externalReservationSettings.showPhoneNumber : location['notificationShowPhoneNumber'] || false;
      location.reservationStartOffset = externalReservationSettings.minMinutesToBook || 0;
      location.reservationMaxTimeInAdvance = (externalReservationSettings.maxMinutesToBook || 28 * 24 * 60) / 24 / 60;
      location.reservationMaxSize = externalReservationSettings.maxSize || 2;
      location.reservationNote = externalReservationSettings.note || '';
    }

    const reservationSettings = reservationSettingsObjects.find((reservationSetting) => reservationSetting.serviceType === ReservationServiceType.TABLE);
    if (reservationSettings && reservationSettings.isActivated) {
      location.useReservations = reservationSettings.isActivated || false;
      location.reservationType = 'internal';
      location.reservationServices = reservationSettings.serviceProviders?.join(', ') || '';
      location.reservationExternalUrl = reservationSettings.externalUrl || '';
      location.reservationEmails = (reservationSettings.emails || location['notificationEmails'])?.join(',') || '';
      location.reservationPhoneNumber = reservationSettings.phoneNumber || location['notificationPhoneNumber'] || '';
      location.reservationTextMessage = typeof reservationSettings.enableTextMessages === 'boolean' ? reservationSettings.enableTextMessages : location['notificationEnableTextMessages'] || false;
      location.reservationShowPhoneNumber = typeof reservationSettings.showPhoneNumber === 'boolean' ? reservationSettings.showPhoneNumber : location['notificationShowPhoneNumber'] || false;
      location.reservationStartOffset = reservationSettings.minMinutesToBook || 0;
      location.reservationMaxTimeInAdvance = (reservationSettings.maxMinutesToBook || 28 * 24 * 60) / 24 / 60;
      location.reservationMaxSize = reservationSettings.maxSize || 2;
      location.reservationNote = reservationSettings.note || '';
    }

    return location;
  }
}
