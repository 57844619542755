import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { classToPlain } from 'class-transformer';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Reservation, ReservationRejectionReason } from '../models/reservation';


/**
 * Class providing access methods for reservations.
 */
@Injectable({
  providedIn: 'root'
})
export class ReservationAccess {

  private reservationCollection: AngularFirestoreCollection<Reservation>;

  /**
   * The default constructor.
   */
  constructor(
    private afs: AngularFirestore,
  ) {
    this.reservationCollection = afs.collection<Reservation>('reservations');
  }

  /**
   * Add new Reservation to cloud.
   *
   * @param reservation the Reservation object to add
   * @return the id of the new Reservation
   */
  public async addReservation(reservation: Reservation): Promise<string> {
    if (!reservation.id) {
      reservation.id = this.afs.createId();
      reservation.createdAt = new Date().toISOString();
    }
    reservation.updatedAt = new Date().toISOString();

    await this.reservationCollection.doc(reservation.id).set(classToPlain(this.makeReservationUpwardCompatible(reservation)) as Reservation, { merge: true });

    return reservation.id;
  }

  /**
   * Returns all Reservations of a Location.
   *
   * @param locationId the ID of the Location
   * @param fromTime the from date in RFC 3339 format
   * @param toTime the to date in RFC 3339 format
   * @returns the found Reservations, otherwise empty list
   */
  public getAllReservations(locationId?: string, fromTime?: string, toTime?: string): Observable<Reservation[]> {
    return this.afs.collection<Reservation>('reservations', ref => {
      let newRef = ref.orderBy('createdAt');

      if (locationId !== undefined) {
        newRef = newRef.where('locationId', '==', locationId);
      }
      if (fromTime !== undefined) {
        newRef = newRef.where('createdAt', '>=', fromTime);
      }
      if (toTime !== undefined) {
        newRef = newRef.where('createdAt', '<', toTime);
      }

      return newRef;
    }).valueChanges().pipe(
      map((reservationsJson) => {
        return (reservationsJson as Reservation[]).map((reservation) => this.makeReservationDownwardCompatible(reservation)); // plainToClass(Customer, customersJson as object[]);
      })
    );
  }

  /**
   * Returns all Reservations of a Customer.
   *
   * @param customerId the ID of the Customer
   * @returns the found Reservations, otherwise empty list
   */
  public getReservationsOfCustomer(customerId: string): Observable<Reservation[]> {
    return this.afs.collection<Reservation>('reservations', ref => ref.where('customerId', '==', customerId).orderBy('diningDateAndTime'))
      .valueChanges().pipe(
        map((reservationsJson) => {
          return (reservationsJson as Reservation[]).map((reservation) => this.makeReservationDownwardCompatible(reservation)); // plainToClass(Reservation, reservationsJson as object[]);
        })
      );
  }

  /**
   * Returns the Reservation specified by the ID.
   *
   * @param reservationId the Reservation ID
   * @returns the found Reservation, otherwise undefined
   */
  public getReservation(reservationId: string): Observable<Reservation> {
    return this.reservationCollection.doc(reservationId).valueChanges().pipe(
      map((reservationJson) => {
        return this.makeReservationDownwardCompatible(reservationJson as Reservation); // plainToClass(Reservation, reservationJson);
      })
    );
  }

  /**
   * Returns the Reservation specified by the cancellation code.
   *
   * @param cancellationCode the cancellation code
   * @returns the found Reservation, otherwise undefined
   */
  public getReservationByCancellationCode(cancellationCode: string): Observable<Reservation | undefined> {
    return this.afs.collection<Reservation>('reservations', ref => ref.where('cancellationCode', '==', cancellationCode))
      .valueChanges().pipe(
        map((reservationsJson) => {
          return reservationsJson as Reservation[]; // plainToClass(Reservation, reservationsJson as object[]);
        }),
        map((reservations: Reservation[]) => reservations.length > 0 ? this.makeReservationDownwardCompatible(reservations[0]) : undefined)
      );
  }

  /**
   * Removes Reservations.
   *
   * @param reservationIds the IDs of the Reservations to remove
   */
  public async removeReservations(reservationIds: string[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const reservationId of reservationIds) {
      batch.delete(this.reservationCollection.doc(reservationId).ref);
    }

    await batch.commit();
  }

  /**
   * Changes status of Reservations.
   *
   * @param reservationIds the IDs of the Reservations to change
   * @param status possible values are 'request', 'confirmed', 'cancelled'
   */
  public async setStatusOfReservations(reservationIds: string[], status: string): Promise<void> {
    const batch = this.afs.firestore.batch();

    for (const reservationId of reservationIds) {
      batch.update(this.reservationCollection.doc(reservationId).ref, { status: status });
    }

    await batch.commit();
  }

  private makeReservationDownwardCompatible(reservation: Reservation): Reservation {
    if (reservation.status === 'rejected') {
      reservation.status = 'cancelled:' + reservation.rejectionReason;
    } else if (reservation.status === 'cancelled') {
      reservation.status = 'cancelled:self';
    }
    reservation.rejectionReason = null;
    return reservation;
  }

  private makeReservationUpwardCompatible(reservation: Reservation): Reservation {
    // const oldStatus = reservation.status;
    // if (oldStatus.startsWith('cancelled:self')) {
    //   reservation.status = 'cancelled';
    //   reservation.rejectionReason = null;
    // } else if (oldStatus.startsWith('cancelled:')) {
    //   reservation.status = 'rejected';
    //   reservation.rejectionReason = oldStatus.split(':')[1] as ReservationRejectionReason;
    // }
    return reservation;
  }
}
