import { AngularFireAuth } from "@angular/fire/auth";


class HttpBaseAccess {
  async getAuthHeader(afAuth: AngularFireAuth): Promise<HeadersInit> {
    const token = await (await afAuth.currentUser).getIdToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  }
}

export const httpBaseAccess = new HttpBaseAccess();