import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReservationSettings } from '../models/reservation-settings';
import { ReservationSettingsAccess } from '../access/reservation-settings-access.service';

/**
 * Class providing management methods for reservation settings.
 */
@Injectable({
  providedIn: 'root'
})
export class ReservationSettingsManager {

  /**
   * The default constructor.
   */
  constructor(
    private reservationSettingsAccess: ReservationSettingsAccess
  ) { }

  /**
   * Returns all reservation settings for a location.
   *
   * @param locationId the ID of the location
   * @returns the found reservation settings, otherwise empty list
   */
  public getReservationSettings(locationId: string): Observable<ReservationSettings[]> {
    return this.reservationSettingsAccess.getReservationSettings(locationId);
  }
}
