import { Location } from '../models/location';
import { OrderServiceType, OrderSettings } from '../models/order-settings';

/**
 * Utility class for OrderSettings.
 */
export class OrderSettingsAdapter {
  public static mapOrderSettingsToLocation(location: Location, orderSettingsObjects: OrderSettings[]): Location {
    location.orderingEmails = location['notificationEmails']?.join(',') || '';
    location.orderingPhoneNumber = location['notificationPhoneNumber'] || '';
    location.orderingSupportedDiets = location['supportedDietIds']?.join(',') || '';
    location.orderingOrderdirectVisibility = 'hidden';
    location.orderingTextMessage = false;
    location.orderingUseTips = '';
    location.orderingSendReceipts = '';

    const dineInOrderSettings = orderSettingsObjects.find((orderSetting) => orderSetting.serviceType === OrderServiceType.DINE_IN);
    if (dineInOrderSettings && dineInOrderSettings.isActivated) {
      location.orderingDineIn = true;
      location.orderingDineInPaymentMethodTypes = dineInOrderSettings.paymentMethodTypes?.join(',') || '';
      location.orderingDineInInputFields = dineInOrderSettings.inputFields?.join(',') || '';
      location.orderingDineInText = dineInOrderSettings.note || '';
      location.orderingDineInAsap = dineInOrderSettings.enableAsap;
      location.orderingDineInPreOrder = dineInOrderSettings.enableAdvance;
      location.orderingPickupStartOffset = dineInOrderSettings.minMinutesToBook;
      location.orderingDineInPreOrderMaxOffset = dineInOrderSettings.maxMinutesToBook / 24 / 60;
      location.orderingServices = dineInOrderSettings.serviceProviders?.join(',') || '';
      location.orderingOrderdirectVisibility = dineInOrderSettings.isActivated ? 'visible' : 'hidden';
      location.orderingTextMessage = location.orderingTextMessage || dineInOrderSettings.enableTextMessages || false;
      location.orderingSendReceipts = dineInOrderSettings.enableReceipts ? [...location.orderingSendReceipts.split(','), 'DINE_IN'].join('') : location.orderingSendReceipts;
      location.orderingUseTips = dineInOrderSettings.enableTips ? [...location.orderingUseTips.split(','), 'DINE_IN'].join('') : location.orderingUseTips;
    } else {
      location.orderingDineIn = false;
      location.orderingDineInAsap = false;
      location.orderingDineInPreOrder = false;
      location.orderingPickupStartOffset = 0;
      location.orderingDineInPreOrderMaxOffset = 0;
    }

    const pickupOrderSettings = orderSettingsObjects.find((orderSetting) => orderSetting.serviceType === OrderServiceType.PICKUP);
    if (pickupOrderSettings && pickupOrderSettings.isActivated) {
      location.orderingPickup = true;
      location.orderingPaymentMethodTypes = pickupOrderSettings.paymentMethodTypes?.join(',') || '';
      location.orderingPickupInputFields = pickupOrderSettings.inputFields?.join(',') || '';
      location.orderingPickupText = pickupOrderSettings.note || '';
      location.orderingPickupAsap = pickupOrderSettings.enableAsap;
      location.orderingPickupPreOrder = pickupOrderSettings.enableAdvance;
      location.orderingPickupStartOffset = pickupOrderSettings.minMinutesToBook;
      location.orderingPickupPreOrderMaxOffset = pickupOrderSettings.maxMinutesToBook / 24 / 60;
      location.orderingServices = pickupOrderSettings.serviceProviders?.join(',') || '';
      location.orderingOrderdirectVisibility = (location.orderingOrderdirectVisibility === 'visible' || pickupOrderSettings.isActivated) ? 'visible' : 'hidden';
      location.orderingTextMessage = location.orderingTextMessage || pickupOrderSettings.enableTextMessages || false;
      location.orderingSendReceipts = pickupOrderSettings.enableReceipts ? [...location.orderingSendReceipts.split(','), 'PICKUP'].join('') : location.orderingSendReceipts;
      location.orderingUseTips = pickupOrderSettings.enableTips ? [...location.orderingUseTips.split(','), 'PICKUP'].join('') : location.orderingUseTips;
    } else {
      location.orderingPickup = false;
      location.orderingPickupAsap = false;
      location.orderingPickupPreOrder = false;
      location.orderingPickupStartOffset = 0;
      location.orderingPickupPreOrderMaxOffset = 0;
    }

    const deliveryOrderSettings = orderSettingsObjects.find((orderSetting) => orderSetting.serviceType === OrderServiceType.DELIVERY);
    if (deliveryOrderSettings && deliveryOrderSettings.isActivated) {
      location.orderingDelivery = true;
      location.orderingPaymentMethodTypes = deliveryOrderSettings.paymentMethodTypes?.join(',') || '';
      location.orderingDeliveryInputFields = deliveryOrderSettings.inputFields?.join(',') || '';
      location.orderingDeliveryText = deliveryOrderSettings.note || '';
      location.orderingDeliveryAsap = deliveryOrderSettings.enableAsap;
      location.orderingDeliveryPreOrder = deliveryOrderSettings.enableAdvance;
      location.orderingDeliveryStartOffset = deliveryOrderSettings.minMinutesToBook;
      location.orderingDeliveryPreOrderMaxOffset = deliveryOrderSettings.maxMinutesToBook / 24 / 60;
      location.orderingServices = deliveryOrderSettings.serviceProviders?.join(',') || '';
      location.useSpecialDeliveryTimes = !!deliveryOrderSettings.serviceHours;
      location.orderingDeliveryTimes = deliveryOrderSettings.serviceHours || null;
      location.orderingDeliveryRules = deliveryOrderSettings.deliveryRules || null;
      location.orderingOrderdirectVisibility = (location.orderingOrderdirectVisibility === 'visible' || deliveryOrderSettings.isActivated) ? 'visible' : 'hidden';
      location.orderingTextMessage = location.orderingTextMessage || deliveryOrderSettings.enableTextMessages || false;
      location.orderingSendReceipts = deliveryOrderSettings.enableReceipts ? [...location.orderingSendReceipts.split(','), 'SHIPMENT'].join('') : location.orderingSendReceipts;
      location.orderingUseTips = deliveryOrderSettings.enableTips ? [...location.orderingUseTips.split(','), 'SHIPMENT'].join('') : location.orderingUseTips;
    } else {
      location.orderingDelivery = false;
      location.orderingDeliveryAsap = false;
      location.orderingDeliveryPreOrder = false;
      location.orderingDeliveryStartOffset = 0;
      location.orderingDeliveryPreOrderMaxOffset = 0;
      location.useSpecialDeliveryTimes = false;
    }

    return location;
  }
}
