import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OrderSettings } from '../models/order-settings';
import { OrderSettingsAccess } from '../access/order-settings-access.service';

/**
 * Class providing management methods for order settings.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderSettingsManager {

  /**
   * The default constructor.
   */
  constructor(
    private orderSettingsAccess: OrderSettingsAccess
  ) { }

  /**
   * Returns all order settings for a location.
   *
   * @param locationId the ID of the location
   * @returns the found order settings, otherwise empty list
   */
  public getOrderSettings(locationId: string): Observable<OrderSettings[]> {
    return this.orderSettingsAccess.getOrderSettings(locationId);
  }
}
